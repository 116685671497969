import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
    },
    rootHeaderItem: {
        marginTop: theme.spacing(2),
        marginBottom: 0,
    },
    subtitle: {
        marginBottom: theme.spacing(0.75),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    title: {
        marginBottom: theme.spacing(1.25),
        color: theme.palette.logo.blue.main,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    description: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    imageWrapper: {
        position: 'relative',
    },
    headerImageWrapper: {
        [theme.breakpoints.up('xl')]: {
            textAlign: 'center',
        },
    },
    image: {},
}));
