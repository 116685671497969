import React from 'react';
import clsx from 'clsx';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import useStyles from './styles';

const FeatureItem = (props) => {
    const { title, description, subtitle, direction, image, alignItems, absolutePositionedImage, actionButtons, headerItem } =
        props;

    const classes = useStyles(props);
    const theme = useTheme();
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid
            container
            className={headerItem ? classes.rootHeaderItem : classes.root}
            direction={direction}
            spacing={8}
            alignItems={alignItems}
            justify="center"
        >
            <Grid item md={6} xs={12}>
                {Boolean(subtitle && subtitle.trim()) && (
                    <Typography variant="subtitle1" component="div" className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                )}
                <Typography variant="h2" className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
                {actionButtons}
            </Grid>
            <Grid item md={6} xs={12} className={clsx(classes.imageWrapper, { [classes.headerImageWrapper]: headerItem })}>
                <GatsbyImage
                    image={image}
                    className={classes.image}
                    alt={title}
                    style={absolutePositionedImage && isScreenSizeMd ? { position: 'absolute' } : undefined}
                />
            </Grid>
        </Grid>
    );
};

FeatureItem.defaultProps = {
    subtitle: '',
    direction: 'row',
    alignItems: 'center',
    absolutePositionedImage: false,
    actionButtons: null,
    headerItem: false,
};

FeatureItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    direction: PropTypes.oneOf(['row', 'row-reverse']),
    image: PropTypes.oneOfType([PropTypes.any]).isRequired,
    alignItems: PropTypes.string,
    absolutePositionedImage: PropTypes.bool,
    headerItem: PropTypes.bool,
    actionButtons: PropTypes.node,
};

export default FeatureItem;
