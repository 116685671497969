import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        background: theme.palette.logo.blue.main,
        borderRadius: 10,
        color: theme.palette.common.white,
        width: 200,
        minWidth: 200,
        maxWidth: 200,
        padding: theme.spacing(4, 0, 2, 0),
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
    iconWrapper: {
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: 10,
        display: 'inline-block',
        margin: theme.spacing(0, 'auto', 3, 'auto'),
        transform: 'rotate(-15deg)',
        boxShadow: '5px 6px 11px 0 rgba(0, 0, 0, 0.16)',
    },
    icon: {
        width: 45,
        maxWidth: 45,
        maxHeight: 45,
        height: 45,
        fontSize: theme.typography.pxToRem(45),
        color: theme.palette.logo.blue.main,
    },
}));
