import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    sectionOneWrapper: {
        paddingTop: theme.spacing(5),
        background: '#f8faff',
        borderRadius: '0 0 50px 50px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        marginBottom: theme.spacing(30),
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(9),
        },
    },
    sectionOneContent: {
        margin: theme.spacing(2, 0, 2.5, 0),
    },
    sectionOneImage: {
        display: 'block',
        margin: theme.spacing(-22.5, 'auto', 0, 'auto'),
        maxWidth: 800,
        width: '100%',
        position: 'relative',
        bottom: -210,
    },
    trustedByGrid: {
        maxWidth: 800,
        margin: theme.spacing(2, 'auto', 5, 'auto'),
        paddingTop: theme.spacing(2),
    },
    trustedByLogo: {
        display: 'block !important',
        margin: theme.spacing(1, 'auto'),
    },
    extraInfoWrapper: {
        background: '#f8faff',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        boxShadow: '0 3px 22px 0 rgba(46, 46, 46, 0.12)',
    },
    extraInfoTitle: {
        margin: theme.spacing(10, 0, 4, 0),
        '&:first-child': {
            marginTop: 0,
        },
    },
    statisticsWrapper: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: 500,
            margin: 'auto',
        },
    },
    badgesGrid: {
        marginTop: theme.spacing(3),
    },
}));
