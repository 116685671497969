import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        color: theme.palette.logo.blue.main,
        fontSize: 'inherit',
        display: 'inline-block',
    },
    underlined: {
        borderBottom: `2px solid ${theme.palette.logo.blue.main}`,
    },
}));
