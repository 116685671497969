import React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import Button from '../button';

const BookADemoButton = (props) => {
    return (
        <Button component={Link} to="/book-a-demo" {...props}>
            Book a demo
        </Button>
    );
};

BookADemoButton.defaultProps = {
    variant: 'text',
};

BookADemoButton.propTypes = {
    variant: PropTypes.string,
};

export default BookADemoButton;
