import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const HighlightedSpan = (props) => {
    const { underlined, ...restProps } = props;

    const classes = useStyles();

    return (
        <span
            className={clsx(classes.root, {
                [classes.underlined]: underlined,
            })}
            {...restProps}
        />
    );
};

HighlightedSpan.defaultProps = {
    underlined: false,
};

HighlightedSpan.propTypes = {
    underlined: PropTypes.bool,
};

export default HighlightedSpan;
