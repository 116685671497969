import React, { useEffect, useState } from 'react';
import camelcaseKeys from 'camelcase-keys';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import client from '$landing-page/client';
import BookADemoButton from '$landing-page/components/book-a-demo-button';
import Button from '$landing-page/components/button';
import FeatureItem from '$landing-page/components/feature-item';
import HighlightedSpan from '$landing-page/components/highlighted-span';
import PageHeader from '$landing-page/components/page-header';
import PageSection from '$landing-page/components/page-section';
import PageWrapper from '$landing-page/components/page-wrapper';
import StartNowButton from '$landing-page/components/start-now-button';

import StatisticsCard from './statistics-card';
import useStyles from './styles';

const imagesQuery = graphql`
    query homeIndexPage {
        dashboardsPreview: file(relativePath: { eq: "page-home-dashboards-preview.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1200, quality: 100, placeholder: NONE)
            }
        }
        logoACTG: file(relativePath: { eq: "logo-act-government.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 28, quality: 100, placeholder: NONE)
            }
        }
        logoJHU: file(relativePath: { eq: "logo-johns-hopkins-university.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 23, quality: 100, placeholder: NONE)
            }
        }
        logoMGU: file(relativePath: { eq: "logo-mcgill-university.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 24, quality: 100, placeholder: NONE)
            }
        }
        logoOR: file(relativePath: { eq: "logo-open-research.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 25, quality: 100, placeholder: NONE)
            }
        }
        logoTU: file(relativePath: { eq: "logo-tilburg-university.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 39, quality: 100, placeholder: NONE)
            }
        }
        logoUCL: file(relativePath: { eq: "logo-university-college-london.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 22, quality: 100, placeholder: NONE)
            }
        }
        logoUA: file(relativePath: { eq: "logo-university-of-amsterdam.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 26, quality: 100, placeholder: NONE)
            }
        }
        logoWSU: file(relativePath: { eq: "logo-western-sydney-university.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 25, quality: 100, placeholder: NONE)
            }
        }
        imageFeatureExperienceSampling: file(relativePath: { eq: "feature-experience-sampling.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 100, placeholder: NONE)
            }
        }
        imageFeatureAvailableEverywhere: file(relativePath: { eq: "available-everywhere.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 100, placeholder: NONE)
            }
        }
        imageFeatureSensorData: file(relativePath: { eq: "feature-sensor-data.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 900, quality: 100, placeholder: NONE)
            }
        }
        badgeGDPRCompliant: file(relativePath: { eq: "badge-gdpr-compliant.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 57, quality: 100, placeholder: NONE)
            }
        }
        badgeHIPAACompliance: file(relativePath: { eq: "badge-hipaa-compliance.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 71, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const HomeIndexPage = (props) => {
    const { location } = props;

    const classes = useStyles();
    const queryData = useStaticQuery(imagesQuery);

    const [participationCount, setParticipationCount] = useState('26607');
    const [publicationsCount, setPublicationsCount] = useState('174');
    const [studiesCount, setStudiesCount] = useState('198');
    const [institutionsCount, setInstitutionsCount] = useState('95');

    const trustedByLogos = [
        { query: queryData.logoACTG, imageAlt: 'ACT Government' },
        { query: queryData.logoJHU, imageAlt: 'Johns Hopkins University' },
        { query: queryData.logoOR, imageAlt: 'Open Research' },
        { query: queryData.logoTU, imageAlt: 'Tilburg University' },
        { query: queryData.logoUCL, imageAlt: 'University College London' },
        { query: queryData.logoUA, imageAlt: 'University of Amsterdam' },
        { query: queryData.logoWSU, imageAlt: 'Western Sydney University' },
        { query: queryData.logoMGU, imageAlt: 'McGill University' },
    ];

    useEffect(() => {
        let isUnmounted = false;
        client.api
            .get('/study/statistics/total/')
            .then((response) => {
                if (!isUnmounted) {
                    const data = camelcaseKeys(response.data);
                    if (data.totalParticipation > 0) {
                        setParticipationCount(data.totalParticipation);
                    }
                    if (data.totalStudies > 0) {
                        setStudiesCount(data.totalStudies);
                    }
                    if (data.totalPublications > 0) {
                        setPublicationsCount(data.totalPublications);
                    }
                    if (data.totalOrganizations > 0) {
                        setInstitutionsCount(data.totalOrganizations);
                    }
                }
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            });
        return () => {
            isUnmounted = true;
        };
    }, []);

    return (
        <PageWrapper pageMetaData={{ title: 'Home', pathname: location.pathname }}>
            <PageHeader homePage />
            <PageSection className={classes.sectionOneWrapper}>
                <Grid container>
                    <Grid item container xs={12} md direction="column" alignItems="center">
                        <Typography variant="h1" component="h2" align="center">
                            Patient-centric&nbsp;
                            <HighlightedSpan underlined>Clinical Trial</HighlightedSpan> and
                            <br />
                            <HighlightedSpan underlined>Health Research</HighlightedSpan>&nbsp; Software Platform
                        </Typography>
                        <Typography variant="subtitle2" component="p" align="center" className={classes.sectionOneContent}>
                            Set up a website and app for your participants within minutes,
                            <br />
                            and automate the field deployment, data collection, and remote monitoring of your patients.
                        </Typography>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item>
                                <StartNowButton />
                            </Grid>
                            <Grid item>
                                <BookADemoButton />
                            </Grid>
                        </Grid>
                        <GatsbyImage
                            image={queryData.dashboardsPreview.childImageSharp.gatsbyImageData}
                            className={classes.sectionOneImage}
                            alt="Ethida Dashboards Preview"
                        />
                    </Grid>
                </Grid>
            </PageSection>
            <PageSection>
                <Grid container spacing={2} className={classes.trustedByGrid} alignItems="center">
                    {trustedByLogos.map((logo, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item sm={3} xs={6} key={index}>
                            <GatsbyImage
                                image={logo.query.childImageSharp.gatsbyImageData}
                                className={classes.trustedByLogo}
                                alt={logo.imageAlt}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="subtitle2" align="center" component="p">
                    Trusted by more than&nbsp;
                    <HighlightedSpan>1000 researchers</HighlightedSpan>
                    &nbsp;from&nbsp;
                    <HighlightedSpan>{institutionsCount} distinguished institutions</HighlightedSpan>
                </Typography>
            </PageSection>
            <PageSection>
                <FeatureItem
                    title="Outcome Assessment"
                    subtitle="A Reliable and Featureful Outcome Assessment Platform"
                    description="Ethica provides a complete toolbox for health and clinical research assessments, including clinical outcome assessments (eCOA), patient-reported outcomes (ePROs), clinician-reported outcome (ClinRo), momentary assessments (EMAs), and cognitive tasks. You can easily add any of the existing validated standard sets to your study, or implement your own."
                    direction="row-reverse"
                    image={queryData.imageFeatureExperienceSampling.childImageSharp.gatsbyImageData}
                    actionButtons={
                        <Button component={Link} to="/features/outcome-assessment">
                            Learn More
                        </Button>
                    }
                />
                <FeatureItem
                    title="TeleVisit"
                    subtitle="Fast and Secure Platform for Virtual Visits"
                    description="Stay in touch with participants and guide them throughout your study. Whether you want to walk them through the consent forms, help them with one of the study tasks, or monitor their medication adherence, Ethica’s Televisit can simplify your communication with the study patients."
                    image={queryData.imageFeatureAvailableEverywhere.childImageSharp.gatsbyImageData}
                    actionButtons={
                        <Button component={Link} to="/features/televisit">
                            Learn More
                        </Button>
                    }
                />
                <FeatureItem
                    title="Sensor Data"
                    subtitle="Sensor-based Quantitative Behavior Measurement"
                    description="Ethica offers integration with a wide range of smartphone sensors and wearables that your study can use to objectively measure behavior or verify self-reports."
                    direction="row-reverse"
                    image={queryData.imageFeatureSensorData.childImageSharp.gatsbyImageData}
                    actionButtons={
                        <Button component={Link} to="/features/sensor-based-data">
                            Learn More
                        </Button>
                    }
                />
            </PageSection>
            <PageSection className={classes.extraInfoWrapper}>
                <Typography variant="h2" align="center" className={classes.extraInfoTitle}>
                    Ethica in Numbers
                </Typography>
                <Grid container justify="center" spacing={2} className={classes.statisticsWrapper}>
                    <Grid item>
                        <StatisticsCard value={studiesCount} title="Research Studies" icon="microscope" />
                    </Grid>
                    <Grid item>
                        <StatisticsCard value={publicationsCount} title="Publications" icon="book" />
                    </Grid>
                    <Grid item>
                        <StatisticsCard value={institutionsCount} title="Institutions" icon="university" />
                    </Grid>
                    <Grid item>
                        <StatisticsCard value={participationCount} title="Participants" icon="user-astronaut" />
                    </Grid>
                    <Grid item>
                        <StatisticsCard value="42 Billion" title="Data Records" icon="chart-network" />
                    </Grid>
                </Grid>
                <Typography variant="h2" align="center" className={classes.extraInfoTitle}>
                    A&nbsp;
                    <HighlightedSpan>Security-First</HighlightedSpan>
                    &nbsp;Research Platform
                </Typography>
                <Typography variant="body1" align="center">
                    In Ethica, we understand the sensitivity of your research data. So protecting the privacy of the participants,
                    <br />
                    the security and integrity of the data, and the reliability of our service is our highest priority.
                </Typography>
                <Grid container justify="center" spacing={8} className={classes.badgesGrid}>
                    <Grid item>
                        <GatsbyImage
                            image={queryData.badgeHIPAACompliance.childImageSharp.gatsbyImageData}
                            alt="HIPAA Compliance Badge"
                        />
                    </Grid>
                    <Grid item>
                        <GatsbyImage
                            image={queryData.badgeGDPRCompliant.childImageSharp.gatsbyImageData}
                            alt="GDPR Compliant Badge"
                        />
                    </Grid>
                </Grid>
            </PageSection>
        </PageWrapper>
    );
};

HomeIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default HomeIndexPage;
